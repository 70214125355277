/**
 * download-card
 */

import $ from 'jquery';

// Module dependencies
import 'protons';

// Module styles
import './_download-card.scss';

// Module template
import './_download-card.twig';

export const name = 'download-card';

export const defaults = {
  dummyClass: 'js-download-card-exists',
};

const queryString = require('query-string');

/* eslint-disable */
/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context, settings) {
  const ns = 'downloadCard';
  let module = {
    /**
     * Drupal environment flag.
     */
    drupal: false,

    /**
     * Initialized flag.
     */
    init: false,
  };

  /**
   * Renders the iframe.
   * @param {HtmlNode} $elem: The downlaod card element.
   * @param {*} data: Traceparts Iframe service data.
   */
  function prepareIframe($elem, data) {
    // Returns false if viewer_ui is not defined.
    if (!data.viewer_ui) {
      $elem.remove();
      return false;
    }

    $('iframe#download-card-ifrm-img').remove();

    const ifrm = document.createElement('iframe');
    ifrm.setAttribute('src', data.viewer_ui);
    ifrm.id = 'download-card-ifrm-img';
    $elem.find('.download-card__card img').remove();
    $elem.find('.download-card__card').prepend(ifrm);
    $elem
      .find('.download-card__dropdown')
      .addClass('download-card__dropdown--loaded');
    $elem.find('select.form-item__select').on('change', function () {
      const url = this.value;
      $elem.find('.download-card__link a').attr('href', url);
    });
    $elem.addClass('download-card--loaded');
    const event = new Event('download-card-loaded');
    document.dispatchEvent(event);
    return true;
  }

  /**
   * Render Download options
   * @param {HtmlNode} $elem: The downlaod card element.
   * @param {*} options: The downlaod files urls.
   */
  function prepareDownloadOptions($elem, options) {
    $elem.find('select.form-item__select').html('');
    let counter = 0;
    for (let option in options.options) {
      const opt = document.createElement('option');
      opt.value = option;
      opt.innerHTML = options.options[option];
      $elem.find('select.form-item__select').append(opt);
      if (counter == 0) {
        $elem.find('.download-card__link a').attr('href', option);
        $elem.find('.download-card__link a').attr('target', '_blank');
      }
      counter++;
    }
  }

  /**
   * Render 'login to download' button.
   * @param {HtmlNode} $elem: The downlaod card element.
   */
  function prepareLoginButton($elem) {
    $elem.find('.form-item').html('');
    const icon = $elem.find('.button--secondary svg').clone();
    $elem
      .find('.button--secondary')
      .text(Drupal.t('login to download'))
      .attr('href', '')
      .append(icon)
      .on('click', function (ev) {
        ev.preventDefault();
        SFIDWidget.login();
      });
  }

  /**
   * Returns data to be used for the CAD download card.
   * @param {HTMLNode} $elem: The download card element.
   * @param {string} partNum: The product item's part number.
   */
  function getDownloadCardData($elem, partNum) {
    $.ajax({
      url: '/api/traceparts/viewer',
      method: 'GET',
      data: {
        part_number: partNum,
      },
    })
      .done(function (iframeData) {
        // CHheck authentication state
        $.ajax({
          url: '/ajax/ecommerce/user',
          data: {},
          method: 'POST',
          crossDomain: true,
          dataType: 'json',
          timeout: 30000,
        })
          .done(function (userData) {
            // Verify user
            const user = userData.authenticated ? userData.username : null;
            if (!!user) {
              $.ajax({
                url: '/api/traceparts/options',
                method: 'GET',
                data: {
                  part_number: partNum,
                },
              })
                .done(function (options) {
                  prepareDownloadOptions($elem, options);
                  prepareIframe($elem, iframeData);
                })
                .fail(function (xhr) {
                  console.error(xhr);
                  prepareLoginButton($elem);
                  prepareIframe($elem, iframeData);
                });
            } else {
              prepareLoginButton($elem);
              prepareIframe($elem, iframeData);
            }
          })
          .fail(function (xhr) {
            // If auth could not be verified
            console.error(xhr);
            prepareLoginButton($elem);
            prepareIframe($elem, iframeData);
          });
      })
      .fail(function (xhr) {
        // Hide iframe section and update layout.
        $('.download-card').hide();
        $('.facet-list').addClass('facet-list--left');
        console.error(xhr);
      });
  }

  /**
   * Sets the data to be used for the CAD download card.
   * @param {HTMLNode} $elem: The download card element.
   */
  function setDownloadCardData($elem, context) {
    const $selector = $('.traceparts-info-data-selector', context);
    $selector.once('traceparts-info-data-selector').change(function () {
      setDownloadCardData($elem, context);
    });
    const tracepartsInfo = $selector.data('traceparts-info');
    const pid = $selector.val();
    if (window.Cookies.get('Drupal.visitor.sf_session')) {
      prepareDownloadOptions($elem, {options: tracepartsInfo.downloadOptions[pid]})
    }
    else {
      prepareLoginButton($elem);
    }
    prepareIframe($elem, {viewer_ui: tracepartsInfo.viewerUri[pid]});
  }

  /**
   * Drupal behavior attachament.
   */
  module.attachment = function (context, settings) {
    // Write shared patternlab drupal code here
    if (!module.drupal) {
      // Exlusive Patternlab interacions
      $('.download-card', context).each(function () {
        $(this).addClass('download-card--loaded');
        $(this)
          .find('.download-card__dropdown')
          .addClass('download-card__dropdown--loaded');
      });
    }

    if (module.drupal && !module.init) {
      module.init = true;
      const parsed = queryString.parse(location.search);
      const $wedgeLokConfigurator = $('#wedgelok-configurator');
      $('.download-card', context).each(function () {
        const $elem = $(this);
        // If there is a selectionPath param in the browser URL upon page load,
        // first get the part # according to the selectionPath via the API.
        // This part # will be used to get the data for the CAD Download
        // instead of using the value coming from the node's Catalog Number
        // field. If there is no selectionPath in the browser URL upon page
        // load, the part # from the node's Catalog Number field will be used.
        if (parsed.selectionPath && $wedgeLokConfigurator.length) {
          // const $wedgeLokPartID = $wedgeLokConfigurator.attr('partid');
          // $.ajax({
          //   url: '/api/traceparts/PartNumberData',
          //   data: {
          //     PartID: $wedgeLokPartID,
          //     selectionPath: parsed.selectionPath,
          //   },
          //   method: 'GET',
          //   crossDomain: true,
          //   dataType: 'json',
          //   cache: false,
          //   timeout: 30000,
          // })
          //   .done(function (selectionPathData) {
          //     let partNum = selectionPathData.bomFields[2].value;
          //     getDownloadCardData($elem, partNum)
          //   })
          //   .fail(function (xhr) {
          //     reject(xhr);
          //   });
        }
        else if (!$wedgeLokConfigurator.length) {
          const pid = $elem.data('product-id');
          if (pid === 'PRELOADED') {
            // See TracepartsViewerBlock.
            setDownloadCardData($elem, context);
          }
          else {
            getDownloadCardData($elem, pid);
          }
        }
      });
    }
  };

  // Forced initializer for Drupal Behaviors
  try {
    if (Drupal.behaviors) {
      module.drupal = true;
      // Attaching once
      if (Drupal.behaviors[ns] === undefined) {
        Drupal.behaviors[ns] = {
          attach: module.attachment,
        };
        // Invoke behavior as particle already capture it.
        module.attachment($context[0], settings);
      }
    }
  } catch (error) {
    window.onload = (event) => {
      console.log('ERRR3');
      module.attachment(document, {});
    };
  }
}

export default enable;
/* eslint-enable */
