/**
 * cadenas
 */

import $ from 'jquery';

// Module dependencies
import 'protons';

// Module styles
import './_cadenas.scss';

// Module template
import './_cadenas.twig';

export const name = 'cadenas';

export const defaults = {
  dummyClass: 'js-download-cadenas-exists',
};

/* eslint-disable */
/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context, settings) {
  const cadenas_api = {
    'cadqualifiers': '/api/cadenas/cadqualifiers',
    'download': '/api/cadenas/download',
    'preview': '/api/cadenas/preview',
    'catalog': '/api/cadenas/catalog',
  };
  const ns = 'cadenas';
  const module = {
    /**
     * Drupal environment flag.
     */
    drupal: false,

    /**
     * Initialized flag.
     */
    init: false,
  };

  module.setDownloadButton = ($el, id) => {
    const $downloadButton = $el.find('.button--file-download');
    const enableDownload = () => {
      $el.find('.file-download-options').removeClass('hidden');
      $el.find('.drawing-type').removeClass('hidden');
      $downloadButton.text('Download');
      // Download button (Logged-in user)
      $downloadButton.click(function(e){
        e.preventDefault();
        e.stopPropagation();
        const $downloadButton = $(this);
        const $loading = $el.find('.loading-indicator');
        $downloadButton.addClass('disabled');
        $loading.removeClass('hide');
        const quality = $el.find('input[name=quality]:checked').val();
        const mounting_plate = $el.find('input[name=mounting-plate]:checked').val();
        const selectedFormat = $el.find('input[name=format]:checked').val();
        const format = $el.find(`.file-types-${selectedFormat}-format`).val();

        // Force reset state
        $el.find('iframe.file-download').remove();
        // File download request
        let url = `${module.apiBaseUrl}${cadenas_api.download}?id=${id}&quality=${quality}&format=${format}`;
        if (mounting_plate) {
          url += '&mounting_plate=1';
        }
        $.ajax({url}).done((data) => {
          $loading.addClass('hide');
          $downloadButton.removeClass('disabled');
          if (data.path) {
            const iframe = $(`<iframe class="file-download" src="${data.path}"></iframe>`);
            $el.find('.file-download-wrapper').append(iframe);
          }
          if (data.message) {
            alert(data.message);
          }
        });
      });
    }
    if (window.Cookies.get('Drupal.visitor.sf_session')) {
      enableDownload();
      return;
    }

    // Check logged-in user
    let loggedIn = false;
    $.ajax({
      url: '/ajax/ecommerce/user',
      data: {},
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      timeout: 30000,
    })
      .done(function (userData) {
        loggedIn = userData.authenticated ? userData.username : null;
      })
      .then(() => {
        if (!!!loggedIn) {
          $downloadButton.unbind('click').click(function (e){
            e.preventDefault();
            e.stopPropagation();
            SFIDWidget.login();
          });
        } else {
          enableDownload();
        }
      });
  };

  /**
   * Drupal behavior attachament.
   */
  module.attachment = function (context, settings) {
    if (!module.init) {
      module.init = true;
      module.apiBaseUrl = module.drupal ? '' : 'http://hoffman.nvent.docksal.site/en-us';
      const api_base_url = module.apiBaseUrl;
      $('.cadenas-component', context).each(function() {
        const $el = $(this);
        const id = $el.data('id');
        if (id) {
          module.setDownloadButton($el, id);
          // Preview
          $el.find('iframe.cad-viewer').attr('src', `${api_base_url}${cadenas_api.preview}?id=${id}`);

          const $el2d = $el.find('select.file-types-2d-format');
          const $el3d = $el.find('select.file-types-3d-format');
          $.ajax({
            url: api_base_url + cadenas_api.cadqualifiers,
            method: 'GET',
            dataType: 'json',
            crossDomain: true,
          }).done(function (cadqualifiers){
            const newOption = (data) => {
              if (data && data.qualifier) {
                const label = data.name + (data.version ? ` (${data.version})` : '');
                return `<option value="${data.qualifier}">${label}</option>`;
              }
            };
            if (cadqualifiers.cad2d) {
              cadqualifiers.cad2d.forEach(function (data){
                const option = newOption(data);
                if (option) {
                  $el2d.append(option);
                }
              });
            }
            if (cadqualifiers.cad3d) {
              cadqualifiers.cad3d.forEach(function (data){
                const option = newOption(data);
                if (option) {
                  $el3d.append(option);
                }
              });
            }

            $('.icon-link--3d-model', document).removeClass('icon-link--hide');
          }).fail(function (xhr) {
            $el.hide();
            console.error('Error requesting cadenas_api.cadqualifiers', xhr);
          });

          // Format selector
          $el.find('input[name=format]').click(function () {
            const $radio = $(this);
            const isChecked = $radio.is(':checked');
            let format = $radio.val();
            $el.find(`.file-types-${format}-format-wrapper`).toggle(isChecked);
            format = format === '2d' ? '3d' : '2d';
            $el.find(`.file-types-${format}-format-wrapper`).toggle(!isChecked);
          });
          $el.find('input[name=format]:checked').click();

          // Drawing type selector
          $el.find('input[name=drawing-type]').click(function () {
            const $radio = $(this);
            if ($radio.val() === 'express') {
              $el.find('.file-download-options').find('.form-item, select').addClass('disabled');
              // Defaults to DXF 2D low quality format including mounting plate.
              $el.find('.file-types-2d-format').val('DXF2D-2010');
              $el.find('input[name=format][value=2d],input[name=quality][value=low]').click();
              const $mountingPlate = $el.find('input[name="mounting-plate"]');
              if (!$mountingPlate.is(':checked')) {
                $mountingPlate.click();
              }
            } else {
              $el.find('.disabled').removeClass('disabled');
            }
          });
          $el.find('input[name=drawing-type]:checked').click();
        }
      });
    }
  };

  // Forced initializer for Drupal Behaviors
  try {
    module.drupal = window.Drupal && window.Drupal.behaviors;
    if (module.drupal) {
      // Attaching once
      if (Drupal.behaviors[ns] === undefined) {
        Drupal.behaviors[ns] = {
          attach: module.attachment,
        };
        // Invoke behavior as particle already capture it.
        module.attachment($context[0], settings);
      }
    } else {
      $(document).ready(function(){
        module.attachment(document, {});
      });
    }
  } catch (error) {
    console.log(`Error loading ${ns} component.`)
  }
}

export default enable;
/* eslint-enable */
