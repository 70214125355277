import { h } from 'preact';

export function AddToFavorites({ t, product, addToFavoritesHandler }) {
  return (
    <div class="purchase-block__add-favorites">
      <a
        class={
          product.ready
            ? 'link link--alt js-link-exists'
            : 'link link--alt js-link-exists disabled'
        }
        tabIndex={-1}
        onClick={addToFavoritesHandler}
      >
        {product.add_to_fav_label
          ? product.add_to_fav_label
          : t('Add to wishlist')}
        <svg class="icon js-icon-exists" aria-hidden="true" role="img">
          <use
            xlinkHref={`/themes/custom/particle/dist/app-drupal/assets/images/sprite.svg#sprite-plus`}
          ></use>
        </svg>
      </a>
    </div>
  );
}
