/**
 * product-detail
 */

// Module dependencies
import 'protons';

// Module styles
import './_product-detail.scss';

// Module template
import './_product-detail.twig';

export const name = 'product-detail';

export const defaults = {
  dummyClass: 'js-product-detail-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

export function enable() {}

export default enable;
