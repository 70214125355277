/**
 * feature-box
 */

import $ from 'jquery';

// Module dependencies
import 'protons';

// Module styles
import './_feature-box.scss';

// Module template
import './_feature-box.twig';

const picturefill = require('picturefill');

export const name = 'feature-box';

export const defaults = {
  dummyClass: 'js-feature-box-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context) {
  // Find reference to first and last feature box in list.
  const featureBoxes = [];
  $('.nvent-layout--featured', $context).each(function findFeatureBoxes() {
    const $featureBoxes = $(this).find('.feature-box');
    if ($featureBoxes.length > 0) {
      featureBoxes.push($featureBoxes.first());

      if ($featureBoxes.length > 1) {
        featureBoxes.push($featureBoxes.last());
      }
    }
    featureBoxes.push($(this).find('.feature-box').last());
  });

  // Set up window resize function.
  let fbResizeTimeout;

  const windowResizeFunc = () => {
    for (let i = 0; i < featureBoxes.length; i += 1) {
      const $featureBox = featureBoxes[i];

      if ($featureBox.width() > 880) {
        $featureBox.find('source.wide').prop('media', '(min-width: 1000px)');
      } else {
        $featureBox.find('source.wide').prop('media', '(max-width: 0px)');
      }
    }

    // Trigger picturefill udpate.
    picturefill({
      reevaluate: true,
    });
  };

  const debounceWindowResizeFunc = () => {
    clearTimeout(fbResizeTimeout);
    fbResizeTimeout = setTimeout(windowResizeFunc, 100);
  };

  $(window).on('resize', debounceWindowResizeFunc);

  // Manually call resize func once on load.
  windowResizeFunc();
}

export default enable;
