/**
 * main-menu
 */

/* eslint-disable */

import $ from 'jquery';

// Module dependencies
import 'protons';

// Module styles
import './_main-menu.scss';
import './_main-menu-item.scss';
import './_main-menu-link.scss';
import './_main-menu-toggle.scss';

// Module template
import './_main-menu.twig';

export const name = 'main-menu';

export const defaults = {
  dummyClass: 'js-main-menu-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context) {
  let toggle_expand = $('#toggle-expand', $context);

  let menu = document.getElementById('main-nav');

  // Mobile Menu Show/Hide.
  toggle_expand.on('click', function (e) {
    toggle_expand.toggleClass('toggle-expand--open');
    toggle_expand.parent('.toggle-bar').toggleClass('toggle-bar--open');
    $('#main-nav', $context).toggleClass('main-nav--open');
    document.body.classList.toggle('js-scroll-lock');
    document.body.classList.toggle('js-mobile-nav-open');
  });

  function toggleSubList(e) {
    let el = e.currentTarget;
    let menu_item = el.parentElement.parentElement;
    let sub_menu = el.parentElement.nextElementSibling;

    menu_item.classList.toggle('main-menu__item--open');
    sub_menu.classList.toggle('main-menu--sub-open');
  }

  // Expose mobile sub menu on click.
  let submenu_toggles = $('#main-nav', $context).find('.js-menu-toggle');
  for (let i = 0; i < submenu_toggles.length; i++) {
    submenu_toggles[i].addEventListener('click', toggleSubList);
  }
  $(window).bind('resize', function () {
    if ($(window).width() > 919) {
      $('body').removeClass('js-scroll-lock, js-mobile-nav-open');
    } else {
      if ($('.main-nav--open').length > 0) {
        $('body').addClass('js-scroll-lock, js-mobile-nav-open');
      }
    }
    const event = new Event('afterHeaderResize');
    window.dispatchEvent(event);
  });

  // Expose header search form on click
  const toggle_expand_search = $('.toggle-expand--search', $context);
  toggle_expand_search.on('click', () => {
    $('.site-search__form', $context).toggleClass('is-open');
  });
}

export default enable;
