/**
 * sku-detail
 */

import $ from 'jquery';

// Module dependencies
import 'protons';

// Module styles
import './_sku-detail.scss';

// Module template
import './_sku-detail.twig';

const anchorScroll = require('anchor-scroll');

const queryString = require('query-string');

export const name = 'sku-detail';

export const defaults = {
  dummyClass: 'js-sku-detail-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context) {
  const $adminBarCheck = $('#toolbar-administration');
  if ($context === document) {
    const offsetValue = $adminBarCheck.length
      ? 0
      : -1 * $('.mega-menu').first().height();
    // Enabling anchors
    anchorScroll.init({
      updateUrl: true,
      offset: offsetValue - 120,
      ease: 'in-quad',
      duration: 400,
      selector: ".container-product-item a[href*='#']",
    });

    if (!$adminBarCheck.length) {
      $(document).ready(function drd() {
        if (window.location.hash) {
          const $clickElem = $(`a[href="${window.location.hash}"]`);
          if ($clickElem.length) {
            setTimeout(function cto() {
              $clickElem[0].click();
            }, 400);
          }
        }
      });
    }
  }

  // Find our component within the DOM
  const $skuTable = $('#availability-content.table--hidden', $context);
  // Bail if component does not exist
  if (!$skuTable.length) {
    return;
  }

  // eslint-disable-next-line no-undef
  /* global drupalSettings:true  */
  const countryOfUser = drupalSettings.region_manager.current_country.toUpperCase();

  /**
   * Renders the Mectronics Resellers/SKU table.
   * @param {string} availabilityEndpoint: The availability endpoint.
   */
  function getSKUTableData(availabilityEndpoint) {
    $.ajax({
      url: availabilityEndpoint,
      method: 'GET',
      data: {
        countryOfUser,
      },
      crossDomain: true,
      dataType: 'json',
      timeout: 30000,
    })
      .done(function cb(data) {
        if (data.StockCheck && data.StockCheck.status) {
          const payload = data.StockCheck;
          const $tbody = $('tbody', $skuTable);
          $tbody.empty();
          const keys = ['Quantity', 'InvDate'];
          for (let i = 0; i < payload.rCount; i += 1) {
            const $trow = $(document.createElement('tr'));

            const $distLink = $(document.createElement('td'));
            const distContent = `
            <a href="${payload.resultlist[i].Buy}"
            class="link--distributor-title" target="_blank">${payload.resultlist[i].DistName}
            </a>
            `;
            $distLink.html(distContent);
            $trow.append($distLink);
            for (let j = 0; j < keys.length; j += 1) {
              const $tdata = $(document.createElement('td'));
              $tdata.html(payload.resultlist[i][keys[j]]);
              $trow.append($tdata);
            }
            const $buyLink = $(document.createElement('td'));
            const linkContent = `
          <a href="${payload.resultlist[i].Buy}"
          class="link link--alt" target="_blank">${Drupal.t('Shop')}
            <svg class="icon" aria-hidden="true" role="img">
                    <use xlink:href="/themes/custom/particle/dist/app-drupal/assets/images/sprite.svg#sprite-arrow-right"></use>
            </svg>
          </a>
          `;
            $buyLink.html(linkContent);
            $trow.append($buyLink);
            $tbody.append($trow);
          }
        } else if (data.error) {
          $skuTable.hide();
          document
            .querySelector('price-block')
            .dispatch('removeAvailability()', true);
          return;
        } else {
          $('tbody tr > td:nth-child(1)', $skuTable).html(data.message);
        }
        if (
          window.location.hash &&
          window.location.hash === '#availability-content'
        ) {
          const $clickElem = $(`a[href="#availability-content"]`);
          if (!$adminBarCheck.length && $clickElem.length) {
            setTimeout(function cto() {
              $clickElem[0].click();
            }, 400);
          }
        }
        $skuTable.removeClass('table--hidden');
      })
      .fail(function ecb(xhr) {
        console.error(xhr);
        document
          .querySelector('price-block')
          .dispatch('removeAvailability()', true);
      });
  }
  // If there is a selectionPath param in the browser URL upon page load,
  // first get the part # according to the selectionPath via the API. This
  // part # will be used to get the data for the SKU table instead of using the
  // value coming from the node's Catalog Number field. If there is no
  // selectionPath in the browser URL upon page load, the part # from the
  // node's Catalog Number field will be used.
  const parsed = queryString.parse(window.location.search);
  const $wedgeLokConfigurator = $('#wedgelok-configurator');
  let avlEndpoint = '';
  if (parsed.selectionPath && $wedgeLokConfigurator.length) {
    const $wedgeLokPartID = $wedgeLokConfigurator.attr('partid');
    $.ajax({
      url: '/api/traceparts/PartNumberData',
      data: {
        partFamilyCode: $wedgeLokPartID,
        selectionPath: parsed.selectionPath,
      },
      method: 'GET',
      crossDomain: true,
      dataType: 'json',
      timeout: 30000,
    })
      .done((selectionPathData) => {
        const partNum = selectionPathData.bomFields[2].value;
        const selectedCountryLang = `/${drupalSettings.region_manager.current_language}-${drupalSettings.region_manager.current_country}`;
        avlEndpoint = `${selectedCountryLang}/api/availability/${partNum}`;
        getSKUTableData(avlEndpoint);
      })
      .fail((xhr) => {
        // eslint-disable-next-line no-undef
        reject(xhr);
      });
  } else {
    // eslint-disable-next-line no-undef
    avlEndpoint = drupalSettings.nvent_api.endpoints.availability;
    getSKUTableData(avlEndpoint);
  }
}

export default enable;
