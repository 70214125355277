/**
 * footer
 */

// Module dependencies
import 'protons';

// Module template
import './brand-footer/_brand-footer.twig';
import './utility-footer/_utility-footer.twig';

// Module styles
import './brand-footer/_brand-footer.scss';
import './utility-footer/_utility-footer.scss';

export const name = 'footer';

export function disable() {}

export function enable() {}

export default enable;
