/**
 * product-hero
 */

import $ from 'jquery';

// Module dependencies
import 'protons';

// Module styles
import './_product-hero.scss';

// Module template
import './_product-hero.twig';

export const name = 'product-hero';

export const defaults = {
  dummyClass: 'js-product-hero-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context, { productHero = {} }) {
  // Find our component within the DOM
  const $productHero = $('.product-hero', $context);
  // Bail if component does not exist
  if (!$productHero.length) {
    return;
  }

  const $prodHeroInfoListItems = $(
    '.hoffman-product-hero__description-item',
    $context
  );
  const $prodHeroInfoShowHide = $('.hoffman-product-hero__link', $context);

  // Hides/shows product hero info list items for Hoffman SKU pages.
  const toggleProdHeroInfoListItems = ($listItems) => {
    $listItems.each((i, el) => {
      if ($(el).hasClass('hoffman-product-hero__description-item--hide')) {
        $(el).removeClass('hoffman-product-hero__description-item--hide');
      } else if (
        i > 6 &&
        !$(el).hasClass('hoffman-product-hero__description-item--hide')
      ) {
        $(el).addClass('hoffman-product-hero__description-item--hide');
      }
    });
  };

  // Only display the 'Show More'/'Show Less' if there are more than
  // 7 list/bullet items.
  if ($prodHeroInfoListItems.length && $prodHeroInfoListItems.length > 7) {
    $prodHeroInfoShowHide.css('display', 'flex');
  }

  let showHideText = $prodHeroInfoShowHide.text().trim();
  $prodHeroInfoShowHide.click(() => {
    toggleProdHeroInfoListItems($prodHeroInfoListItems);
    $prodHeroInfoShowHide.text(
      showHideText === 'Show More'
        ? (showHideText = 'Show Less')
        : (showHideText = 'Show More')
    );
  });

  // Merge defaults with incoming settings
  const settings = {
    ...defaults,
    ...productHero,
  };
  // An example of what could be done with this component
  $productHero.addClass(settings.dummyClass);
}

export default enable;
