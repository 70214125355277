import { RxJsModelBase } from '../shared/RxJsModelBase';
import { IRxjsModule } from '../shared/Interface/IRxjsModule';
import { PriceBlockModule } from './PriceBlockModule';
import { ConfiguratorsModule } from './ConfiguratorsModule';
import Quantity  from '../web-components/price-block/app/Quantity';

// Main E-commerce controller
export class CommerceModule extends RxJsModelBase implements IRxjsModule {
  // Static instance for singleton
  private static instance: CommerceModule;
  // Salesforse User auth state

  private user: null | string;

  private loadingStr;
  /**
   * Private constructor to enforce Singleton
   */
  private constructor() {
    super();
    this.user = null;
    this.loadingStr = `(...${this.t('loading')})`;
  }

  /**
   * Singleton model
   */
  static getInstance(): CommerceModule {
    if (!CommerceModule.instance) {
      CommerceModule.instance = new CommerceModule();
    }
    return CommerceModule.instance;
  }

  /**
   * Setting namespace for drupal behavior
   */
  getNamespace(): string {
    return 'ecommerceModule';
  }

  /**
   * Checks if apiPricing is disabled (Currently disabled for Hoffman)
   */
  isApiPricingDisabled(): boolean {
    return (window as any).drupalSettings.site.brand === 'hoffman';
  }

  /**
   * Return elements to subscribe (CSS Selector)
   */
  getSubscribersSelector() {
    return 'commerce-cart,commerce-icon';
  }

  /**
   * Events listener
   * @param data {key: method name, value: payload}
   */
  reducer(data) {
    const ctx = CommerceModule.getInstance();
    if (data.key) {
      switch (data.key) {
        case 'addProductToCart()':
          // Add product service
          ctx.addProductToCart(data);
          break;
        case 'addProductToFavorites()':
          // Add product service
          ctx.addProductToFavorites(data);
          break;
        case 'addProductToRequest()':
          // Add product service
          ctx.addProductToRequest(data);
          break;
        case 'checkRegionInvariance()':
          // Product additions is centralized in commerce
          ctx.checkRegionCartInvariant();
          break;
        case 'removeCartProduct()':
          // Product additions is centralized in commerce
          ctx.removeCartProduct(data.value);
          break;
        case 'showAlertMessage()':
          const payload = {
            type: 'error',
            replacements: {},
            message: '',
            ...data.value,
          };
          ctx.showAlert(payload.message, payload.type, payload.replacements);
          break;
        case 'updateCartProduct()':
          // Product additions is centralized in commerce
          ctx.updateCartItemQuantity(
            data.value.id,
            data.value.quantity,
            data.value.index
          );
          break;
      }
    }
  }

  /**
   * Behavior implmentation
   * @param context Drupal behavior context
   * @param settings Drupal Settings
   */
  behavior(context, settings) {
    const $ = (window as any).jQuery;
    // This module will react only once and it will be the main module
    // Authentication state will be handled here and distributed to the other modules.
    const body = context.querySelector('body');
    if (body && !body.classList.contains('ecommerce-js')) {
      body.classList.add('ecommerce-js');
      // enable price block
      PriceBlockModule.getInstance().enable();
      // Enable Coinfgurators module
      ConfiguratorsModule.getInstance().enable();
      // Get user async and update state in auth web components/ modules
      this.checkAuthState().then((authState: any) => {
        this.user = authState.authenticated ? authState.username : null;
        // Notify User
        this.dispatchOutputEvent('updateUser()', this.user);
        // Notify and send the cart state
        this.notifyCartUpdate();
      });
    }

    $("a.link--add-to-cart:not(.add-to-cart-processed), .link--add-to-cart > a:not(.add-to-cart-processed)")
      .addClass('add-to-cart-processed')
      .click(function (e) {
        e.preventDefault();
        const payload = $(this).data('add-to-cart-payload');
        if (payload) {
          const drupalSettings = (window as any).drupalSettings;
          const lang = `${drupalSettings.region_manager.current_language}-${drupalSettings.region_manager.current_country}`;
          payload.url = `${window.location.origin}/${lang}${payload.url}`;
          CommerceModule.getInstance().dispatch('addProductToCart()', payload);
        }
      });

    $(document).on('click', '#request-button', function(e) {
      e.preventDefault();
      $('#request-dialog, #request-overlay', context).remove();
    });
  }

  /**
   * Async method to check if the user is authenticated
   */
  checkAuthState() {
    const url = '/ajax/ecommerce/user';
    const $ = (window as any).jQuery;
    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        data: {},
        method: 'POST',
        crossDomain: true,
        dataType: 'json',
        timeout: 30000,
      })
        .done(function (data) {
          resolve(data);
        })
        .fail(function (xhr) {
          reject(xhr);
        });
    });
  }

  /**
   * Translates an item from the local storage offline cart into a salesforce API cart object
   * @param item
   */
  salesforceEcommerceApiProductConversor(item) {
    const region_manager = (window as any).drupalSettings.region_manager;
    const lang =
      region_manager.current_language + '-' + region_manager.current_country;
    let product: any = {
      itemNumber: item.id,
      quantity: item.qty,
      itemImage: item.image,
      itemDescription: item.name,
      englishItemDescription: item.englishName,
      isWedgeLok: item.isWedgeLok,
    };
    if (item.components && item.components.length > 0) {
      product['components'] = item.components.map((comp) => {
        comp[
          'skuUrl'
        ] = `${window.location.origin}/${lang}/p/ENC_${comp.itemNumber}`;
        return comp;
      });
      product[
        'configuratorCopyURL'
      ] = `${window.location.origin}/${lang}${item.configuratorCopyURL}`;
      product[
        'configuratorModifyURL'
      ] = `${window.location.origin}/${lang}${item.configuratorModifyURL}`;
      product['zipURL'] = item.zipURL;
      product['sapGUID'] = item.sapGUID;
      product['configurationID'] = item.configurationID;
    } else {
      // Only NON-iframed configured items have skuUrls
      product['skuUrl'] =
        item.url !== ''
          ? `${window.location.origin}/${lang}/p/ENC_${item.id}?${item.queryVars}`
          : '';
    }
    return product;
  }

  /**
   * Notify subscribers about changes in the cart.
   */
  notifyCartUpdate() {
    let cartContent = this.getCartContent();
    if (!this.getUser()) {
      this.dispatchOutputEvent('cartInfoOfflineUpdate()', cartContent);
    } else {
      // cart merge needs to occur here once (it might remove the local data once merged)
      let products = cartContent.products;
      if (products.length > 0) {
        const payloadArray = products.map((item) => {
          return this.salesforceEcommerceApiProductConversor(item);
        });
        // Merge cart contents
        localStorage.removeItem('commerce_cart_offline');
        this.cartApiAdd(payloadArray, 'cart')
          .then((res) => {
            console.log('Cart synced Succesfully', res);
            this.dispatchOutputEvent('loadIframe()', res);
            this.dispatchOutputEvent('cartInfoOnlineUpdate()', res);
          })
          .catch((err) => {
            this.showAlert(
              "Error - Your cart items couldn't been synced ",
              'error'
            );
            this.dispatchOutputEvent('loadIframe()', false);
            console.error(err);
          });
      } else {
        this.getCartApi()
          .then((res) => {
            this.dispatchOutputEvent('loadIframe()', res);
            this.dispatchOutputEvent('cartInfoOnlineUpdate()', res);
          })
          .catch((err) => {
            this.dispatchOutputEvent('loadIframe()', false);
            this.dispatchOutputEvent('cartInfoOnlineUpdate()', false);
            console.error(err);
          });
      }
    }
  }

  /**
   * Returns the cart state from the corresponding storage
   */
  getCartContent() {
    return localStorage.getItem('commerce_cart_offline')
      ? JSON.parse(localStorage.getItem('commerce_cart_offline') + '')
      : { products: [], lastUpdate: 0 };
  }

  /**
   * Add product to the shopping cart
   * @param data Object with:
   *   - elem: The DOMElement that generated the request
   *   - key: The reducer task "addProductToCart()"
   *   - value: Object with:
   *      - id: Product Catalog Number
   *      - qty: Product Quantity
   *      - image: Product Image
   *      - name: Product Name
   *      - items: array of accesories (configurators only)
   */
  addProductToCart(data): void {
    if (!this.user) {
      this.addProductAnonymousUser(data);
      // Notify new cart state
      this.notifyCartUpdate();
    } else {
      this.addProductAuthUser(data);
    }
  }

  /**
   * Add product to the whish list
   * @param data Object with:
   *   - elem: The DOMElement that generated the request
   *   - key: The reducer task "addProductToCart()"
   *   - value: Object with:
   *      - id: Product Catalog Number
   *      - qty: Product Quantity
   *      - image: Product Image
   *      - name: Product Name
   */
  addProductToFavorites(data): void {
    if (this.user) {
      const product = data.value;
      const payload = {
        itemNumber: product.id,
        isWedgeLok: product.isWedgeLok,
        quantity: product.qty,
        itemImage: product.image,
        skuUrl: product.url,
        itemDescription: product.name,
        englishItemDescription: product.englishName,
      };
      this.cartApiAdd([payload], 'wishlist')
        .then((res) => {
          this.showAlert(
            'Success - @name // @id  added to your wishlist',
            'success',
            { '@name': product.name, '@id': product.id }
          );
          data.elem.dispatch('productAdded()', data);
        })
        .catch((err) => {
          this.showAlert(
            'Error - @name // @id  could not be added to your  wishlist',
            'error',
            { '@name': product.name, '@id': product.id }
          );
          data.elem.dispatch('unlock()');
        });
    }
  }

  /**
   * Add Request product to the localStorage cart
   * @param data Object with:
   *   - elem: The DOMElement that generated the request
   *   - key: The reducer task "addProductToRequest()"
   *   - value: Object with:
   *      - id: Product Catalog Number
   *      - qty: Product Quantity
   *      - image: Product Image
   *      - name: Product Name
   *      - url: Product URL
   *      - netValues: {yourPrice:number, listPrice: number, currency: string}
   *      - options: List of quantity options
   *      - overrideExistingItem: boolean
   *      - queryVars: any additional query vars to append to the URL
   */
  addProductToRequest(data): void {
    if (!this.user) {
      (window as any).SFIDWidget.login();
      data.elem.dispatch('unlock()');
    } else {
      const Drupal = (window as any).Drupal;
      const $ = (window as any).jQuery;
      const userInfo = (window as any).SFIDWidget.openid_response;
      const neededKeys = [userInfo.addr_city, userInfo.addr_street, userInfo.addr_zip, userInfo.custom_attributes.companyName, userInfo.custom_attributes.phone, userInfo.email];
      const getMessage = $('.request-dialog-message').text();
      let isUserValid = 1;
      neededKeys.forEach( (key) => {
        if(!key && isUserValid) {
          this.showMessage(
            '',
            Drupal.t('<p class="text-center">@getMessage</p><p><b>Email Address</b>: @email  <br><b>Street Address</b>: @street  <br><b>City Address</b>: @city  <br><b>Zip Code</b> : @zip <br><b>Company Name</b>: @company <br><b>Phone</b>: @phone</p>'),
            'error-message',
            'Update Address',
            '/customer/settings',
            {'@getMessage': getMessage || '',
              '@email': userInfo.email || 'Empty',
              '@street': userInfo.addr_street || 'Empty',
              '@city': userInfo.addr_city || 'Empty',
              '@zip': userInfo.addr_zip || 'Empty',
              '@company': userInfo.custom_attributes.companyName || 'Empty',
              '@phone': userInfo.custom_attributes.phone || 'Empty'
            }
          );
          isUserValid = 0;
          data.elem.dispatch('unlock()');
          return;
        }
      });


      if(isUserValid) {
        const product = data.value;
        const payload = {
          itemNumber: product.id,
          isWedgeLok: product.isWedgeLok,
          quantity: product.qty,
          itemImage: product.image,
          skuUrl: product.url,
          itemDescription: product.name,
          englishItemDescription: product.englishName,
        };
        this.cartApiAdd([payload], 'sample_request')
          .then((res) => {
            // This console message will remove when the end API is ready.
            console.log(payload);
            this.showMessage(
              Drupal.t('Sample Product Requested Successfully!'),
              Drupal.t('<p class="text-center">Thank you for requesting a sample product. We will reach out to you for further assistance.</p>'),
              'success-message',
            );
            data.elem.dispatch('productAdded()', data);
          })
          .catch((err) => {
            this.showAlert(
              'Error - @name // @id  could not be added to your Request a sample product',
              'error',
              {'@name': product.name, '@id': product.id}
            );
            data.elem.dispatch('unlock()');
          });
      }
    }
  }

  /**
   * Add product to the localStorage cart
   * @param data Object with:
   *   - elem: The DOMElement that generated the request
   *   - key: The reducer task "addProductToCart()"
   *   - value: Object with:
   *      - id: Product Catalog Number
   *      - qty: Product Quantity
   *      - image: Product Image
   *      - name: Product Name
   *      - url: Product URL
   *      - netValues: {yourPrice:number, listPrice: number, currency: string}
   *      - options: List of quantity options
   *      - overrideExistingItem: boolean
   *      - queryVars: any additional query vars to append to the URL
   */
  addProductAnonymousUser(data) {
    const drupalSettings = (window as any).drupalSettings;
    let cartContent = this.getCartContent();
    // It always add the cart no matters if it already exists in cart NVENT-968
    cartContent.lastUpdate = new Date().getTime();

    let newProduct = {
      englishItemDescription: data.value.englishName,
      queryVars: '',
      isWedgeLok: data.value.isWedgeLok,
      ...data.value,
      qty: data.value.qty ? parseInt(data.value.qty) : 1,
      region:
        data.value.region ||
        drupalSettings.region_manager.current_country.toUpperCase(),
      subTotal: '--',
      yourPrice: '--',
      listPrice: '--',
      availability: this.loadingStr,
      netValues: {
        listPrice: 0,
        yourPrice: 0,
        currency: 'USD ',
      },
      components: data.value.components || [],
      options: Array.from({length: 100}, (_, i) => i + 1),
    };
    newProduct['listPrice'] = '--';
    newProduct['yourPrice'] = '--';

    // Check if the product item exists in the cart already and needs to be updated.
    if (
      !!newProduct.overrideExistingItem &&
      newProduct.overrideExistingItem &&
      cartContent.products.find(existingProduct => existingProduct.id == newProduct.id)
    ) {
      cartContent.products = cartContent.products.map((existingProduct) => {
        if (existingProduct.id == newProduct.id) {
          newProduct.qty = existingProduct.qty;
          return newProduct;
        }
        return existingProduct;
      });
    } else {
      // add new product to products array
      cartContent.products.push(newProduct);
    }

    if (!this.isApiPricingDisabled()) {
      const index = cartContent.products.length - 1;
      // Update the cart quantity prices async and then call notify cart again.
      PriceBlockModule.getPriceBlockPricingData(
        data.value.id,
        newProduct.qty,
        newProduct.components
      ).then((pdata: any) => {
        this.updateProductOnIndexWithPricingData(pdata, cartContent, index);
        localStorage.setItem(
          'commerce_cart_offline',
          JSON.stringify(cartContent)
        );
        // checkInvariant
        this.checkRegionCartInvariant();
        // Notify changes
        this.notifyCartUpdate();
      });
    } else {
      // checkInvariant
      this.checkRegionCartInvariant();
      // Notify changes
      this.notifyCartUpdate();
    }

    // Setting item
    localStorage.setItem('commerce_cart_offline', JSON.stringify(cartContent));

    // Updating local storage
    this.showAlert('Success - @name // @sku added to your cart', 'success', {
      '@name': data.value.name,
      '@sku': data.value.id,
    });

    setTimeout(() => {
      data.elem.dispatch('productAdded()', data);
    }, 1000);
  }

  /**
   * Region needs to match current configuration for anonymous cart
   */
  checkRegionCartInvariant(context = null) {
    const region_manager = (window as any).drupalSettings.region_manager;
    const currentRegion = region_manager.current_country.toUpperCase();
    let cart = null;
    if (context) {
      cart = context;
    } else {
      cart = this.getCartContent();
    }
    // Update collectively
    const dict = [];
    let items = [];
    cart.products.map((product, index: number) => {
      if (product.region !== currentRegion) {
        dict.push(index);
        items.push(product);
      }
    });
    if (items.length > 0) {
      // Put all elements in waiting state
      items.map((pitem, index) => {
        const i = dict[index];
        this.putCartItemInWaitingState(cart, i, pitem.qty);
      });
      localStorage.setItem('commerce_cart_offline', JSON.stringify(cart));
      this.notifyCartUpdate();

      if (!this.isApiPricingDisabled()) {
        // Get all prices simultaneusly
        PriceBlockModule.getPriceBlockPricingDataMultipleItems(items).then(
          (pricingData: any) => {
            const pricedItems = pricingData.items;
            pricedItems.map((pitem: any, index) => {
              const i = dict[index];
              this.updateProductOnIndexWithPricingData(
                pricingData,
                cart,
                i,
                index
              );
            });
            localStorage.setItem('commerce_cart_offline', JSON.stringify(cart));
            // Notify changes
            this.notifyCartUpdate();
            this.checkRegionCartInvariant(cart);
          }
        );
      }
    }
  }

  /**
   * Add product to the authenticated user via API
   * @param data Object with:
   *   - elem: The DOMElement that generated the request
   *   - key: The reducer task "addProductToCart()"
   *   - value: Object with:
   *      - id: Product Catalog Number
   *      - qty: Product Quantity
   *      - image: Product Image
   *      - name: Product Name
   *      - components: Product Components
   */
  addProductAuthUser(data) {
    const product = data.value;
    let payload: any = {
      itemNumber: product.id,
      isWedgeLok: product.isWedgeLok,
      quantity: product.qty,
      itemImage: product.image,
      skuUrl: product.url,
      itemDescription: product.name,
      englishItemDescription: product.englishName,
    };
    if (product.components && product.components.length > 0) {
      payload = this.salesforceEcommerceApiProductConversor(product);
    }
    this.cartApiAdd([payload], 'cart')
      .then((res) => {
        this.showAlert(
          'Success - @name // @id  added to your cart',
          'success',
          { '@name': product.name, '@id': product.id }
        );
        data.elem.dispatch('productAdded()', data);
        if (this.user) {
          this.dispatchOutputEvent('cartInfoOnlineUpdate()', res);
        }
      })
      .catch((err) => {
        this.showAlert(
          'Error - @name // @id  could not be added to your cart',
          'error',
          { '@name': product.name, '@id': product.id }
        );
        data.elem.dispatch('unlock()');
      });
  }

  /**
   * Updates a product to set it in waiting state.
   * @param cartContent
   * @param index
   * @param quantity
   */
  putCartItemInWaitingState(cartContent, index, quantity) {
    const region_manager = (window as any).drupalSettings.region_manager;
    cartContent.products[index]['listPrice'] = '--';
    cartContent.products[index]['yourPrice'] = '--';
    cartContent.products[index]['availability'] = this.loadingStr;
    cartContent.products[index][
      'region'
    ] = region_manager.current_country.toUpperCase();
    cartContent.products[index]['qty'] = quantity;
    cartContent.lastUpdate = new Date().getTime();
  }

  /**
   * Updates payload from pricing api and configurators urls in the cart items
   * @param pdata The data received by pricing API
   * @param cartContent Current offline cart Data
   * @param index The index of the product in the cart data.
   * @param pindex The inner index within the pricing data
   */
  updateProductOnIndexWithPricingData(pdata, cartContent, index, pIndex = 0) {
    const region_manager = (window as any).drupalSettings.region_manager;
    const lang =
      region_manager.current_language + '-' + region_manager.current_country;
    const newData: any = pdata;
    const quantity = parseInt(newData.items[pIndex].quantity);
    const listPrice = newData.items[pIndex].listPrice.value;
    const yourPrice = newData.items[pIndex].netPrice.value;
    const currency = newData.items[pIndex].netPrice.currency;
    cartContent.products[index]['listPrice'] = currency + listPrice;
    cartContent.products[index]['yourPrice'] = currency + yourPrice;
    cartContent.products[index]['netValues'] = {};
    cartContent.products[index]['netValues']['listPrice'] = listPrice;
    cartContent.products[index]['netValues']['yourPrice'] = yourPrice;
    cartContent.products[index]['netValues']['currency'] = currency;
    cartContent.products[index]['availability'] = newData.items[0].leadTime;
    cartContent.products[index]['qty'] = quantity;
    // Update URLs with the latest region
    cartContent.products[index]['url'] =
      cartContent.products[index]['url'] !== ''
        ? `${window.location.origin}/${lang}/p/ENC_${cartContent.products[index]['id']}?${cartContent.products[index]['queryVars']}`
        : '';
    if (
      cartContent.products[index].components &&
      cartContent.products[index].components.length > 0
    ) {
      cartContent.products[index]['components'] = cartContent.products[
        index
      ].components.map((comp) => {
        comp[
          'skuUrl'
        ] = `${window.location.origin}/${lang}/p/ENC_${comp.itemNumber}`;
        return comp;
      });
    }
    if (!this.isApiPricingDisabled()) {
      // Quantity Options
      let price = newData.items[pIndex];
      if (cartContent.products[index]['isWedgeLok'] && (window as any).drupalSettings.site.brand === 'schroff') {
        price.MOQ = 100;
      }
      cartContent.products[index]['options'] = Quantity(price);
    }
  }

  /**
   * Updates an existing item quantity
   * @param id catalog number
   * @param quantity
   * @param index the index within the cart data
   * @param sharedContext binded context if available
   */
  updateCartItemQuantity(id, quantity, index: number, sharedContext = null) {
    let cartContent = null;
    if (sharedContext) {
      cartContent = sharedContext;
    } else {
      cartContent = this.getCartContent();
    }
    this.putCartItemInWaitingState(cartContent, index, quantity);
    localStorage.setItem('commerce_cart_offline', JSON.stringify(cartContent));

    if (!this.isApiPricingDisabled()) {
      // Async callback for updating quantities
      // Update the cart quantity prices async and then call notify cart again.
      PriceBlockModule.getPriceBlockPricingData(
        id,
        quantity,
        cartContent.products[index]['components']
      ).then((pdata) => {
        this.updateProductOnIndexWithPricingData(pdata, cartContent, index);
        localStorage.setItem(
          'commerce_cart_offline',
          JSON.stringify(cartContent)
        );
        // Notify changes
        this.notifyCartUpdate();
        // Verify Invariant
        this.checkRegionCartInvariant(cartContent);
      });
    }

    this.notifyCartUpdate();
  }

  /**
   * Remove Product from Cart
   * @param index: The position in the array of products to remove
   */
  removeCartProduct(index: number) {
    let cartContent = this.getCartContent();
    cartContent.products.splice(index, 1);
    cartContent.lastUpdate = new Date().getTime();
    localStorage.setItem('commerce_cart_offline', JSON.stringify(cartContent));
    this.notifyCartUpdate();
  }

  /**
   * Centralized method to get the current user
   */
  getUser() {
    return this.user;
  }

  /**
   * Shows a Drupal message alert
   * @param msg: The string using translatable syntax .ie. 'My fav number is: @number'
   * @param type The message type (error|success)
   * @param replacements: The object with the key replacements {'@number': 7}
   */
  showAlert(msg: string, type: string = 'error', replacements = {}): void {
    const Drupal = (window as any).Drupal;
    Object.keys(replacements).map((key) => {
      replacements[key] = replacements[key].replace('"', '“');
    });
    const message = Drupal.t(msg, replacements);
    Drupal.ajax({
      url: `/ajax/alert?msg=${encodeURI(message)}&type=${type}`,
    }).execute();
  }

  /**
   * Shows a Dialog box message alert
   * @param title: The title of the dialog box.
   * @param message: The string using translatable syntax.
   * @param messageClass: type of message i.e error or success.
   * @param buttonMessage: Text value of the action button.
   * @param href: Url of the action button.
   * @param replacements: The object with the key replacements {'@number': 17}
   */

  showMessage(title= '', message, messageClass, buttonMessage='OK', href='#', replacements = {}) {
    const $ = (window as any).jQuery;
    const Drupal = (window as any).Drupal;
    const mainDiv = $('.main-content');
    const buttonText = Drupal.t(buttonMessage)
    Object.keys(replacements).map((key) => {
      replacements[key] = replacements[key].replace('"', '“');
      // Highlight empty fields.
      if (replacements[key] == 'Empty') {
        message = message.replace(key, '<b class="empty_user_info">' + key + '</b>');
      }
    });
    const messageText = Drupal.t(message, replacements);

    mainDiv.append(`<div id="request-overlay" class="request-overlay"></div>
      <div id="request-dialog" class="request_dialog ${messageClass}">
        <div class="request_dialog-content">
          <h2>${title}</h2>
          <div class="message-text">${messageText}</div>
          <div class="button-wrapper">
            <a href="#" id="request-button" class="${href == '#' ? 'hide': 'button--cancel'} button button--secondary">${Drupal.t('Cancel')}</a>
            <a href="${href}" id="${href == '#' ? 'request-button': 'request-link'}" class="button button--primary">${buttonText}</a>
            <a href="#" id="request-button" class="request_dialog--close" title="Close dialog box"/>
          </div>
        </div>
      </div>`)
  }


  /**
   * Get updated quantity Data SF API
   */
  cartApiAdd(products: Array<any>, cartType: string = 'cart', extra = {}) {
    const $ = (window as any).jQuery;
    let data = {};

    data = {
      ...extra,
      cartType,
      quantity: '',
      items: products,
    };

    return new Promise((resolve, reject) => {
      const endpoint = '/api/cart/add';
      $.ajax({
        url: endpoint,
        data,
        method: 'POST',
        crossDomain: true,
        dataType: 'json',
        timeout: 30000,
      })
        .done(function (data) {
          resolve(data);
        })
        .fail(function (xhr) {
          reject(xhr);
        });
    });
  }

  /**
   * Get cart items count.
   */
  getCartApi() {
    return new Promise((resolve, reject) => {
      const endpoint = '/api/cart/get';
      const $ = (window as any).jQuery;
      $.ajax({
        url: endpoint,
        method: 'GET',
        crossDomain: true,
        dataType: 'json',
        timeout: 30000,
      })
        .done(function (data) {
          resolve(data);
        })
        .fail(function (xhr) {
          reject(xhr);
        });
    });
  }
}
