/**
 * slider
 */

import $ from 'jquery';
import 'slick-carousel';

// Module dependencies
import 'protons';

// Module styles
import './_slider.scss';
import './card-slider/_card-slider.scss';

// Module template
import './_slider.twig';
import './card-slider/_card-slider.twig';

import './icon-chevron.png';

export const name = 'slider';

export const defaults = {
  dummyClass: 'js-slider-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable(context) {
  const $sliders = $('.slider', context);
  const $slideToscroll = $sliders.closest('.card-slider-wrapper').length;
  // Bail if component doesn't exist
  if (!$sliders) {
    return;
  }

  function initSlider($slider) {
    $slider
      .slick({
        slidesToShow: 3,
        slidesToScroll: $slideToscroll ? 3 : 1,
        dots: false,
        arrows: true,
        appendArrows: $slider.siblings('.slider__controls'),
        infinite: false,
        accessibility: true,
        cssEase: 'ease',
        respondTo: 'window',
        touchMove: true,
        height: 'auto',
        responsive: [
          {
            breakpoint: '920',
            settings: 'unslick',
          },
        ],
      })
      .on('setPosition', (event, slick) => {
        const sliderHeight = slick.$slideTrack.height();

        slick.$slides.css('height', `${sliderHeight}px`);
        slick.$slider
          .siblings('.slider__controls')
          .css('top', `${sliderHeight / 2}px`);
      });
  }
  // Initiate slick
  $sliders.each((i, el) => {
    try {
      initSlider($(el));
    } catch (e) {
      console.error(e);
    }
  });

  $(window, context).on('resize', () => {
    if (window.matchMedia('(min-width: 920px)').matches) {
      $sliders.each((i, el) => {
        if (!$(el).hasClass('slick-initialized')) {
          initSlider($(el));
        }
      });
    }
  });
}

export default enable;
