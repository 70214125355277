import $ from 'jquery';
import PriceBlockComponent from './web-components/price-block/price-block-rxjs.tsx';
import CommerceCartComponent from './web-components/commerce-cart/commerce-cart-rxjs.tsx';
import CartIconComponent from './web-components/commerce-cart-icon/commerce-cart-rxjs.tsx';
import WedgelokConfiguratorComponent from './web-components/wedgelok-configurator/wedgelok-configurator-rxjs.tsx';
import { CommerceModule } from './models/CommerceModule.ts';

// Register web components.
customElements.define('price-block', PriceBlockComponent);
customElements.define('commerce-cart', CommerceCartComponent);
customElements.define('commerce-icon', CartIconComponent);
customElements.define('wedgelok-configurator', WedgelokConfiguratorComponent);

const webComponents = {
  drupal: !$('body.pl').length,
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}
/* eslint-disable */
/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
// eslint-disable-next-line no-unused-vars
export function enable($context, settings) {
  if (webComponents.drupal) {
    // Run only if nvent_ecommerce module is enabled.
    if (drupalSettings.nVent && drupalSettings.nVent.eCommerce) {
      CommerceModule.getInstance().enable();
    }
  }
}
