import { h, Fragment } from 'preact';

export function ProductInfo({ t, product }) {
  const isSchroff = (window as any).drupalSettings.site.isSchroff;
  const isEFS = (window as any).drupalSettings.site.isEfs;

  return (
    <Fragment>
      {product.noPricing ? (
        <Fragment>
          <div class="row row--catalog">
            <h4>
              {product.catalog_label ? product.catalog_label : t('Catalog #:')}
            </h4>
            <span>{product.id}</span>
          </div>
          <div class="row row--no-price">
            <span>{t('Pricing and Leadtime available upon request.')}</span>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div class="row row--catalog">
            <h4>
              {product.catalog_label ? product.catalog_label : t('Catalog #:')}
            </h4>
            <span>{product.id}</span>
          </div>
          {isSchroff && (
            <div class="row row--price">
              <h5>
                {product.price_label ? product.price_label : t('Your price:')}
              </h5>
              <span class="link link--alt">
                {product.price.title}
              </span>
            </div>
          )}
          {isSchroff && (
            <div class="row row--list-price">
              <h5 class="italic">
                {product.list_price_label
                  ? product.list_price_label
                  : t('List price (RRP):')}
              </h5>
              <div>
                <div class="italic text-align-right">{product.list_price}</div>
              </div>
              <a href="#footnote" class="w-full text-align-right"><span>{t('Price per piece, excluding VAT')}<span class="no-style">{t('*')}</span></span></a>
            </div>
          )}
          {product.alert && (
            <p class="purchase-block__alert-message text-align-center">
              {product.alert}
            </p>
          )}
        </Fragment>
      )}
      {product.showAvailability && isSchroff && (
        <a
          href="#availability-content"
          class="link link--alt purchase-block__availability"
        >
          {product.list_dist_label
            ? product.list_dist_label
            : t('Distributor availability')}
          <svg class="icon" aria-hidden="true" role="img">
            <use
              xlinkHref={`/themes/custom/particle/dist/app-drupal/assets/images/sprite.svg#sprite-arrow-down-alt`}
            ></use>
          </svg>
        </a>
      )}
    </Fragment>
  );
}
