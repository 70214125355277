/**
 * site-nav
 */

/* eslint-disable */

import $ from 'jquery';

// Module dependencies
import 'protons';

// Module styles
import './_site-nav.scss';

// Module template
import './_nav-tabs.twig';
import './_nested.twig';
import './_site-nav.twig';
import './_nav-menu-tabs.twig';
import './_nested-tab.twig';
import './nvent-raychem-sidebyside-logo.png'

export const name = 'site-nav';

export const defaults = {
    dummyClass: 'js-site-nav-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} context - A piece of DOM
 */
export function enable(context) {
    const $mainContent = $('.main--anon', context);
    const $header = $('.header', context);
    const isLoggedin = !!$('.toolbar-fixed').length;
    let subtractedHeight;

    $(window).on('resize', () => {
        $mainContent.css({
            'padding-top': $header.outerHeight(),
        });
        subtractedHeight = $header.outerHeight() + (isLoggedin ? 90 : 0);
    }).trigger('resize');

    $('.site-nav__item', context).on('mouseenter', function () {
        $(this).find('.site-nav__wrapper').css({
            'max-height': $(window).height() - subtractedHeight,
        });
    }).on('mouseleave', function () {
        $(this).find('.site-nav__wrapper').css({
            'max-height': 0
        });
    }).on('click', '.site-nav__collapse', (e) => {
        $(e.delegateTarget).find('.site-nav__wrapper').css({
            'max-height': 0
        });
    });

    $('.mega-menu__links', context).on('mouseenter', () => {
        setTimeout(() => {
            $('.site-nav__item').addClass('site-nav__item--fast');
        }, 300);
    });
    $('.mega-menu__links', context).on('mouseleave', () => {
        setTimeout(() => {
            $('.site-nav__item').removeClass('site-nav__item--fast');
        }, 300);
    });

    const $navTabs = $(".site-nav-tabs__item", context);
    $navTabs.each(function (element) {
        const $navItem = $(this);
        $navItem.find(".icon-circle").on("click", function (el) {
            let activeClass = "site-nav-tabs__item--active";
            let $siblingEl = $navItem
                .closest(".site-nav-tabs__list")
                .find(".site-nav-tabs__item");

            $siblingEl.removeClass(activeClass);
            // Show active nested nav
            $navItem.addClass(activeClass);

            // Tertiary-level navigation
            const $activeNestedContent = $('#' + $navItem.data('target'));

            activeClass = "site-nav-tabs__content--active";

            $siblingEl = $activeNestedContent
                .closest(".site-nav-tabs__content-wrapper")
                .find(".site-nav-tabs__content");

            // Hide all nested navs
            $siblingEl.removeClass(activeClass);

            // Show active nav
            $activeNestedContent.addClass(activeClass);
        });
    });

    const $productTabs = $(".product-nav-tabs__item", context);
    $productTabs.each(function (element) {
        const $navItem = $(this);
        $navItem.find(".icon-circle").on("click", function (el) {
            let activeClass = "product-nav-tabs__item--active";
            let $siblingEl = $navItem
                .closest(".product-nav-tabs__list")
                .find(".product-nav-tabs__item");

            $siblingEl.removeClass(activeClass);
            // Show active nested nav
            $navItem.addClass(activeClass);

            // Tertiary-level navigation
            const $activeNestedContent = $('#' + $navItem.data('target'));

            activeClass = "product-nav-tabs__content--active";

            $siblingEl = $activeNestedContent
                .closest(".product-nav-tabs__content-wrapper")
                .find(".product-nav-tabs__content");

            // Hide all nested navs
            $siblingEl.removeClass(activeClass);

            // Show active nav
            $activeNestedContent.addClass(activeClass);
        });
    });

    // when parent menu click then its submenu's first child will be active.
    $('.product-nav-menu-tabs__list > ul.product-nav-tabs__list > li').on("click", function() {
        $('.product-nav-menu-tabs__content-wrapper > .product-nav-tabs__content-wrapper > .product-nav-tabs__content--active ').each(function(index) {
            var is_submenu_active = $(this).find('.product-nav-nested-tab__content-wrap > .product-nav-tabs__content-wrapper > .product-nav-nested__section-wrapper > .product-nav-tabs__content').hasClass('product-nav-tabs__content--active');
            if (is_submenu_active == false) {
                $(this).find('.product-nav-nested-tab__content-wrap > .product-nav-tabs__content-wrapper > .product-nav-nested__section-wrapper > .product-nav-tabs__content:first-child').addClass('product-nav-tabs__content--active');
            }
        });
    });
}

export default enable;
