import { h, Fragment } from 'preact';
import { useEffect, useReducer } from 'preact/hooks';
import { AddToCart } from './components/AddToCart';
import { AddToFavorites } from './components/AddToFavorites';
import { AddToRequest } from "./components/AddToRequest";
import { ProductInfo } from './components/ProductInfo';
import { QuantitySelector } from './components/QuantitySelector';
import { ProductSuccessor } from './components/ProductSuccessor';
import Quantity  from './Quantity';
import {CommerceModule} from '../../../models/CommerceModule';

declare var Drupal;
let productData = null;
function RxJsApp(props) {
  const alwaysAllowAddToCart = (window as any).drupalSettings.site.brand === 'hoffman';
  let initialState = JSON.parse(props.product);
  productData = initialState.id;
  // Add inital options
  initialState.options = [];
  initialState.selectedQuantity = null;
  initialState.user = null;
  initialState.product = {};
  initialState.ready = alwaysAllowAddToCart;
  initialState.noPricing = false;
  initialState.showComponents = true;
  initialState.showAvailability = true;
  initialState.queryVars = '';

  // Language
  const region_manager = (window as any).drupalSettings.region_manager;
  const lang =
    region_manager.current_language + '-' + region_manager.current_country;

  // Shothand for drupal t
  function t(str: string, args = {}): string {
    return props.root.t(str, args);
  }

  // Reducer
  const reducer = (productState, message) => {
    switch (message.key) {
      case 'updatePrice()':
        if (message.value.errorFlag === 'false') {
          // list Price
          let price = message.value.items[0];

          if (productState.isWedgeLok && (window as any).drupalSettings.site.brand === 'schroff') {
            price.MOQ = 100;
          }

          // Quantity Options
          productState.options = Quantity(price);

          if (!productState.selectedQuantity) {
            productState.selectedQuantity = productState.options[0] ?? 1;
            const payload = {
              id: productState.id,
              quantity: productState.selectedQuantity,
            };
            props.root.dispatchOutputEvent('refreshQuantity()', payload);
            return { ...productState };
          }
          productState.list_price = `${
            price.listPrice.currency
              ? price.listPrice.currency
              : 'USD '
          }${price.listPrice.value.trim()}`;

          // Your Price
          productState.price.title = `${
            price.netPrice.currency
              ? price.listPrice.currency
              : 'USD '
          }${price.netPrice.value}`;

          // Add net values
          productState.netValues = {
            yourPrice: Number(price.netPrice.value),
            listPrice: Number(price.listPrice.value.trim()),
            currency: price.netPrice.currency
              ? price.listPrice.currency
              : 'USD ',
          };
          // Availability
          product.days = price.leadTime;
          if (product.days == "0") {
            productState.alert = Drupal.t('leadtime available upon request');
          } else {
            productState.alert = Drupal.t(`Ships within @days working days`, {
              '@days': price.leadTime,
            });
          }
          productState.ready = true;

          // Updating noPricing flag
          productState.noPricing =
            productState.netValues.yourPrice == 0 ||
            productState.netValues.listPrice == 0;
          return { ...productState };
        }
        return productState;
      case 'updateQuantity()':
        if (message.value === productState.selectedQuantity) {
          break;
        }
        productState.selectedQuantity = message.value;
        if (!CommerceModule.getInstance().isApiPricingDisabled()) {
          productState.ready = false;
        }
        productState.price.title = '--';
        return { ...productState };
      case 'log()':
        console.log(message);
        return productState;
      case 'updateUser()':
        productState.user = message.value;
        return { ...productState };
      case 'lock()':
        return { ...productState, ready: false };
      case 'unlock()':
        return { ...productState, ready: true };
      case 'productAdded()':
        productState.ready = true;
        return { ...productState };
      case 'pricingAPIReady()':
        if (CommerceModule.getInstance().isApiPricingDisabled()) {
          productState.options = Array.from({length: 100}, (_, i) => i + 1);
          productState.selectedQuantity = 1;
        } else {
          // Only Dispatch refreshQuantity() if this is not a wedgeLok product.
          // Otherwise dispatch refreshQuantity() once the wedgeLok configurator is fully loaded, see updateProductSKU().
          if (!productState.isWedgeLok) {
            const payload = {id: productState.id, quantity: 1};
            props.root.dispatchOutputEvent('refreshQuantity()', payload);
          }
        }
        return { ...productState };
      case 'removeAvailability()':
        return { ...productState, showAvailability: false };
      case 'addAvailability()':
        return { ...productState, showAvailability: true };
      case 'updateProductSKU()':

        if (productState.isWedgeLok && (window as any).drupalSettings.site.brand === 'schroff') {
          const payload = {id: message.value.partNum, quantity: productState.selectedQuantity ?? 100};
          props.root.dispatchOutputEvent('refreshQuantity()', payload);
        }
        else {
          const payload = {id: message.value.partNum, quantity: 1};
          props.root.dispatchOutputEvent('refreshQuantity()', payload);
        }
        return {
          ...productState,
          id: message.value.partNum,
          queryVars: message.value.queryVars,
        };
      default:
        return productState;
    }
  };

  // Use Reducer
  const [product, dispatch] = useReducer<any, any>(reducer, initialState);

  // Select Quantity
  function selectQuantity(event) {
    const option = event.target.value;
    const data = { key: 'updateQuantity()', value: option };
    dispatch(data);
    if (!CommerceModule.getInstance().isApiPricingDisabled()) {
      const payload = { id: product.id, quantity: option };
      props.root.dispatchOutputEvent('refreshQuantity()', payload);
    }
  }

  // Add to Cart
  function addToCart(event) {
    event.preventDefault();
    const data = { key: 'lock()', value: false };
    dispatch(data);
    const pTitle: HTMLElement = document.querySelector('.product-hero__title');
    const elNameEnglish: HTMLElement = document.querySelector('div.product-title');
    const payload = {
      id: product.id,
      isWedgeLok: product.isWedgeLok,
      qty: product.selectedQuantity,
      name: pTitle ? pTitle.innerText : '',
      englishName: elNameEnglish ? elNameEnglish.innerText : '',
      image: `${window.location.origin}/p/ENC_${productData}/i`,
      listPrice: (product.list_price + '').replace('*', ''),
      yourPrice: product.price.title,
      availability: product.days,
      url: `${window.location.origin}/${lang}/p/ENC_${product.id}?${product.queryVars}`,
      options: product.options,
      queryVars: product.queryVars,
      netValues: product.netValues,
    };
    props.root.dispatchOutputEvent('addProductToCart()', payload);
  }

  // Add product to Favorites
  function addToFavorites(event) {
    event.preventDefault();
    const data = { key: 'lock()', value: false };
    dispatch(data);
    const pTitle: HTMLElement = document.querySelector('.product-hero__title');
    const elNameEnglish: HTMLElement = document.querySelector('div.product-title');
    const payload = {
      id: product.id,
      isWedgeLok: product.isWedgeLok,
      qty: product.selectedQuantity,
      name: pTitle ? pTitle.innerText : '',
      englishName: elNameEnglish ? elNameEnglish.innerText : '',
      image: `${window.location.origin}/p/ENC_${product.id}/i`,
      listPrice: (product.list_price + '').replace('*', ''),
      yourPrice: product.price.title,
      availability: product.days,
      queryVars: product.queryVars,
      url: `${window.location.origin}/${lang}/p/ENC_${product.id}?${product.queryVars}`,
      options: product.options,
      netValues: product.netValues,
    };
    props.root.dispatchOutputEvent('addProductToFavorites()', payload);
  }

  // Add product to Request a sample product
  function addToRequest(event) {
    event.preventDefault();
    const data = { key: 'lock()', value: false };
    dispatch(data);
    const pTitle: HTMLElement = document.querySelector('.product-hero__title');
    const elNameEnglish: HTMLElement = document.querySelector('div.product-title');
    const payload = {
      id: product.id,
      isWedgeLok: product.isWedgeLok,
      qty: product.selectedQuantity,
      name: pTitle ? pTitle.innerText : '',
      englishName: elNameEnglish ? elNameEnglish.innerText : '',
      image: `${window.location.origin}/p/ENC_${product.id}/i`,
      listPrice: (product.list_price + '').replace('*', ''),
      yourPrice: product.price.title,
      availability: product.days,
      queryVars: product.queryVars,
      url: `${window.location.origin}/${lang}/p/ENC_${product.id}?${product.queryVars}`,
      options: product.options,
      netValues: product.netValues,
    };
    props.root.dispatchOutputEvent('addProductToRequest()', payload);
  }

  // UseEffect HOOK
  useEffect(() => {
    let subscription = null;
    const service = props.root.getPropertiesService();
    subscription = service.subscribe((data) => {
      dispatch(data);
    });
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, []);

  var dynamicText = {
    "blockLabels": {
      "usd": "Please note, that there is a USD 500 line-item requirement.",
      "quantity": "If you need another quantity, please reach out to your point of sale. You can find them ",
      "here": "here",
      "dot": ".",
    }
  };

  return (
    <div class={`purchase-block ${product.ready && 'purchase-block--ready'}`}>
      {product.market_info.sucessors_components &&
        <ProductSuccessor
          t={t}
          successor={product.market_info.sucessors_components[0]}
          product = {product}
        ></ProductSuccessor>
      }
      {product.market_info.market_status !== 'obsolete' ? (
        <Fragment>
          <ProductInfo t={t} product={product}></ProductInfo>
          <QuantitySelector
            t={t}
            product={product}
            selectQuantityHandler={selectQuantity}
          ></QuantitySelector>
          {product.isWedgeLok && (window as any).drupalSettings.site.brand ==='schroff' && (
            <div class="purchase-block__sales-locator wedge-lok-product"><span>{dynamicText.blockLabels.usd}</span><br></br><span>{dynamicText.blockLabels.quantity}
              <a href={`/${lang}/solutions/schroff/applications/sales-and-distributor-locations`}> {dynamicText.blockLabels.here} </a>{dynamicText.blockLabels.dot}</span>
            </div>
          )}
          <AddToCart
            addToCartHandler={addToCart}
            t={t}
            product={product}
          ></AddToCart>
          {product.isWedgeLok && (window as any).drupalSettings.site.brand ==='schroff' && (
            <AddToRequest
              AddToRequestHandler={addToRequest}
              t={t}
              product={product}
            ></AddToRequest>
          )}
          {product.user && (
            <AddToFavorites
              addToFavoritesHandler={addToFavorites}
              t={t}
              product={product}
            ></AddToFavorites>
          )}
        </Fragment>
      ) : (
        <div>
          <div class="row row--catalog">
            <h4>
              {product.catalog_label ? product.catalog_label : t('Catalog #:')}
            </h4>
            <span>{product.id}</span>
          </div>
          <p class="purchase-block__obsolete purchase-block__message">
            {t('This catalog item is no longer available')}
          </p>
          {}
        </div>
      )}
      {!product.market_info.sucessors_components && product.noPricing && !product.isWedgeLok &&
        <div>
          <a href={`/${lang}/solutions/schroff/applications/sales-and-distributor-locations`} class="button button--secondary w-full mt-4 purchase-block__contact-us">
            {t('Contact us')}
          </a>
        </div>
      }
    </div>
  );
}

export default RxJsApp;
