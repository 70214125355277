import { h, Fragment } from 'preact';
import { CommerceModule } from "../../../../models/CommerceModule";

export function CartSidebar({ t, cart }) {
  const apiPricingDisabled = CommerceModule.getInstance().isApiPricingDisabled();
  // Opens SF login popup
  function openLoginPopup(evt) {
    evt.preventDefault();
    (window as any).SFIDWidget.login();
  }
  var dynamicText = {
    "cartLabels": {
      "plRequest": "Pricing and Leadtime available upon request.",
      "lQuote": "Login to Request Quote",
      "lPrice": "List Price:",
      "yPrice": "Your Price:",
      "mWishlist": "Move cart to wishlist",
    }
  };
  return (
    <div class="commerce-cart__sidebar">
      <div class="cart-summary-block js-cart-summary-block-exists">
        {!apiPricingDisabled && (
          <div>
            {cart.noPricing ? (
              <div class="row cart-summary-block__no-pricing">
                <span>{dynamicText.cartLabels.plRequest}</span>
              </div>
            ) : (
              <Fragment>
                <div class="row cart-summary-block__price">
                  <h5 class="cart-summary-block__label">{dynamicText.cartLabels.yPrice}</h5>
                  <div class="cart-summary-block__value">
                    {cart.yourPriceSubtotal}
                  </div>
                </div>
                <div class="row cart-summary-block__list-price">
                  <h5 class="cart-summary-block__label">{dynamicText.cartLabels.lPrice}</h5>
                  <div class="cart-summary-block__value">
                    {cart.listPriceSubtotal}
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        )}
        <div class="d-flex">
          {!apiPricingDisabled ? (
            <button
              class={!cart.ready ? 'button disabled' : 'button'}
              disabled={!cart.ready}
              onClick={openLoginPopup}
            >
              {' '}
              {dynamicText.cartLabels.lQuote}
            </button>
          ):(
            <button
            className={'button'}
            onClick={openLoginPopup}
            >
          {' '}
          {dynamicText.cartLabels.lQuote}
            </button>
            )}
        </div>
        {cart.user && (
          <div class="d-flex">
            <a href="" class="cart-summary-block__link">
            {dynamicText.cartLabels.mWishlist}
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
