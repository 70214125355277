/**
 * in-page-navigation
 */

/* eslint-disable */

import $ from 'jquery';

// Module dependencies
import 'protons';

// Module styles
import './_in-page-navigation.scss';

// Module template
import './_in-page-navigation.twig';

export const name = 'in-page-navigation';

export const defaults = {
  dummyClass: 'js-in-page-navigation-exists',
};

/**
 * Components may need to run clean-up tasks if they are removed from DOM.
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Pertinent settings
 */
// eslint-disable-next-line no-unused-vars
export function disable($context, settings) {}

/**
 * Each component has a chance to run when its enable function is called. It is
 * given a piece of DOM ($context) and a settings object. We destructure our
 * component key off the settings object and provide an empty object fallback.
 * Incoming settings override default settings via Object.assign().
 *
 * @param {jQuery} $context - A piece of DOM
 * @param {Object} settings - Settings object
 */
export function enable($context) {
  let header = $(".stick-here", $context);
  let stickWrapper = $(".stick-here-wrapper", $context);
  const $inPageNavigation = $(".in-page-navigation", $context);

  if (!$inPageNavigation.length && !header.length) { return; }
  let lastDataLink = $('.js-anchor-link').last();

  $(window).on("scroll", function (e) {
    let header_height = $('.header', $context).outerHeight(true); //calculated height of the Header
    let scroll = $(window).scrollTop();
    let productNavigation = $(header).offset().top - header_height;
    let lastItem = lastDataLink.offset().top + lastDataLink.outerHeight(true);
    if (scroll > productNavigation && scroll < lastItem && !($('body').hasClass('toolbar-fixed'))) {
      stickWrapper.css('transform', 'translateY(0%)');
      stickWrapper.addClass("fixed");
      stickWrapper.css({'top': header_height,zIndex: '12'});
      $($inPageNavigation)
        .css("margin", "0 8px");
    }
    else {
      stickWrapper.removeClass("fixed");
      $(".stick-here .in-page-navigation__links").css("margin-left", "0px");
      $($inPageNavigation)
        .css("margin", "0 auto");
    }
  });

  let $in_page_navigation = $("#in-page-navigation");
  if ($('.js-anchor-link', $context).length){
    $('.js-anchor-link', $context).each(function () {
      let $this = $(this);
      let target = $this.attr('id');
      let name = $this.attr('data-anchor-link');
      let e_url = $this.attr('data-external-link');

      let item = e_url ? `<li class="in-page-navigation__item">
          <a href="${e_url}" class="in-page-navigation__link"> ${name} </a>
        </li>` : `<li class="in-page-navigation__item">
          <a href="#${target}" class="in-page-navigation__link"> ${name} </a>
        </li>`;

      $in_page_navigation.append(item);
    });
  } else {
    $in_page_navigation.closest(".stick-here").hide();
  }

  $in_page_navigation.find('a[href*="#"]').on('click', function (e) {
    let header_height = $('.header', $context).height();
    let pagenav_height = $(".stick-here",  $context).height();
    let top_value = header_height + pagenav_height;
    let navContainerHeight = $('.in-page-navigation__container', $context).height();

    e.preventDefault();

    if ($('.stick-here', $context).hasClass('fixed')) {
      $('html, body').animate(
        {
          scrollTop: $($(this).attr('href')).offset().top - top_value,
        },
        300,
        'linear'
      )
    } else {
      $('html, body').animate(
        {
          scrollTop: $($(this).attr('href')).offset().top - (top_value + navContainerHeight),
        },
        300,
        'linear'
      )
    }
  });
}

export default enable;
