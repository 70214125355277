import { h } from 'preact';

export function ProductSuccessor({ t, successor , product }) {
  return (
    <div class="purchase-block__successor">
      <p class="purchase-block__message">
        {(window as any).drupalSettings.site.brand === 'schroff' && product.market_info.market_status === 'obsolete' ? t('Item is obsolete. Click below for recommended replacement.') : (window as any).drupalSettings.site.brand === 'schroff' && product.market_info.market_status === 'phaseout' ? t('This item is not recommended for new designs. Click the link below for the newer version.') : ''  }
        {(window as any).drupalSettings.site.brand === 'hoffman' && product.market_info.market_status === 'phaseout' ? t('Item is being phased out; soon to become obsolete or superseded.') : (window as any).drupalSettings.site.brand === 'hoffman' && product.market_info.market_status === 'obsolete' ? t('Item is obsolete. Click below for recommended replacement.') : ''}
      </p>
     
      <div class="purchase-block__successor-item">
        {successor.image && (<img src={successor.image} alt={successor.name}></img>)}
        <a href={successor.url}>{successor.name}</a>
      </div> 
    </div>
  );
}
