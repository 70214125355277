import {h} from 'preact';

export function AddToCart({t, product, addToCartHandler}) {
  const isEFS = (window as any).drupalSettings.site.isEfs;
  return (
    !isEFS && (
      <div class="purchase-block__add-cart">
        <a
          class={product.ready == false ? 'button  disabled' : 'button enabled'}
          tabIndex={-1}
          onClick={addToCartHandler}
        >
          {product.add_to_cart_label
            ? product.add_to_cart_label
            : t('Add to cart')}
          <svg class="icon js-icon-exists" aria-hidden="true" role="img">
            <use
              xlinkHref={`/themes/custom/particle/dist/app-drupal/assets/images/sprite.svg#sprite-cart`}
            ></use>
          </svg>
        </a>
      </div>
    )
  );
}
