/**
 * brand-bar
 */

import './nvent-brand-caddy.png';
import './nvent-brand-erico.png';
import './nvent-brand-hoffman.png';
import './nvent-brand-raychem.png';
import './nvent-brand-schroff.png';
import './nvent-brand-tracer.png';

// Module dependencies
import 'protons';

// Module styles
import './_brand-bar.scss';

// Module template
import './_brand-bar.twig';

export const name = 'brand-bar';

export function disable() {}

export function enable() {}

export default enable;
